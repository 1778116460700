<template>
  <div class="voucherPage" :style="{ 'min-height': pageHeight + 'px' }">
    <div class="form">
      <!-- 副标题，户籍 -->
      <div class="subtitle"><span class="titleIcon"></span>户籍（限8张）</div>
      <div class="formChild">
        <!-- 户籍相关资料 -->
        <div class="formItem">
          <van-uploader
            v-model="residenceBookletList"
            :max-count="8"
            :max-size="10485760"
            @oversize="onOversize"
            :after-read="(file) => afterRead(file, 1)"
            :before-delete="beforeDelete"
          >
            <div class="uploadBox">
              <van-image
                width="81px"
                height="93px"
                :src="iconUpload"
                fit="widthFix"
              />
              <div class="tips">请上传户籍相关资料证明</div>
            </div>
          </van-uploader>
        </div>
      </div>
      <div
        v-if="
          isPlatformType1 ||
          isPlatformType2 ||
          isPlatformType3 ||
          isPlatformType13
        "
      >
        <!-- 副标题，产权 -->
        <div class="subtitle">
          <span class="titleIcon"></span>{{ voucherStr
          }}{{ maxCount == 8 ? "（限8张）" : "" }}
        </div>
        <div class="formChild">
          <!-- 产权证明 -->
          <div class="formItem">
            <van-uploader
              v-model="propertyRightList"
              :max-count="maxCount"
              :max-size="10485760"
              @oversize="onOversize"
              :after-read="(file) => afterRead(file, 2)"
              :before-delete="beforeDelete"
            >
              <div class="uploadBox">
                <van-image
                  width="81px"
                  height="93px"
                  :src="iconUpload"
                  fit="widthFix"
                />
                <div class="tips">请上传{{ voucherStr }}</div>
              </div>
            </van-uploader>
          </div>
        </div>
        <!-- 副标题，辅助 -->
        <div class="subtitle"><span class="titleIcon"></span>辅助（限8张）</div>
        <div class="formChild">
          <!-- 其他辅助凭证 -->
          <div class="formItem">
            <van-uploader
              v-model="billsList"
              :max-count="8"
              :max-size="10485760"
              @oversize="onOversize"
              :after-read="(file) => afterRead(file, 3)"
              :before-delete="beforeDelete"
            >
              <div class="uploadBox">
                <van-image
                  width="81px"
                  height="93px"
                  :src="iconUpload"
                  fit="widthFix"
                />
                <div class="tips">请上传辅助相关资料证明</div>
              </div>
            </van-uploader>
          </div>
        </div>
        <!-- 副标题，特殊 -->
        <div class="subtitle"><span class="titleIcon"></span>特殊（限8张）</div>
        <div class="formChild">
          <!-- 其他说明照片 -->
          <div class="formItem">
            <van-uploader
              v-model="specialList"
              :max-count="8"
              :max-size="10485760"
              @oversize="onOversize"
              :after-read="(file) => afterRead(file, 4)"
              :before-delete="beforeDelete"
            >
              <div class="uploadBox">
                <van-image
                  width="81px"
                  height="93px"
                  :src="iconUpload"
                  fit="widthFix"
                />
                <div class="tips">请上传特殊说明相关资料证明</div>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
      <!-- 副标题，高层次人才 -->
      <div v-if="isPlatformType10">
        <div class="subtitle">
          <span class="titleIcon"></span>高层次人才证书（限10张）
        </div>
        <div class="formChild">
          <!-- 产权证明 -->
          <div class="formItem">
            <van-uploader
              v-model="hlTalentsFileList"
              :max-count="10"
              :max-size="10485760"
              @oversize="onOversize"
              :after-read="(file) => afterRead(file, 5)"
              :before-delete="beforeDelete"
            >
              <div class="uploadBox">
                <van-image
                  width="81px"
                  height="93px"
                  :src="iconUpload"
                  fit="widthFix"
                />
                <div class="tips">请上传高层次人才证书</div>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
      <div v-else-if="isPlatformType11">
        <!-- 副标题，军人证书 -->
        <div class="subtitle">
          <span class="titleIcon"></span>军人证书（限10张）
        </div>
        <div class="formChild">
          <!-- 其他辅助凭证 -->
          <div class="formItem">
            <van-uploader
              v-model="militaryFileList"
              :max-count="10"
              :max-size="10485760"
              @oversize="onOversize"
              :after-read="(file) => afterRead(file, 6)"
              :before-delete="beforeDelete"
            >
              <div class="uploadBox">
                <van-image
                  width="81px"
                  height="93px"
                  :src="iconUpload"
                  fit="widthFix"
                />
                <div class="tips">请上传军人证书</div>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
      <div v-else-if="isPlatformType12">
        <!-- 副标题，消防救援人员证书 -->
        <div class="subtitle">
          <span class="titleIcon"></span>消防救援人员证书（限10张）
        </div>
        <div class="formChild">
          <!-- 其他说明照片 -->
          <div class="formItem">
            <van-uploader
              v-model="frPersonnelFileList"
              :max-count="10"
              :max-size="10485760"
              @oversize="onOversize"
              :after-read="(file) => afterRead(file, 7)"
              :before-delete="beforeDelete"
            >
              <div class="uploadBox">
                <van-image
                  width="81px"
                  height="93px"
                  :src="iconUpload"
                  fit="widthFix"
                />
                <div class="tips">请上传消防救援人员证书</div>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
    </div>
    <div class="btnBox">
      <van-button
        color="linear-gradient(315deg, #B1E0DC 0%, #00C3AC 100%)"
        size="large"
        round
        class="submitBtn"
        @click="onSubmit"
        >立即提交</van-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "voucher",
  data() {
    return {
      iconUpload: require("@/assets/images/icon_upload.png"),
      pageHeight: "",
      pageType: "",
      voucherStr: "",
      residenceBookletList: [],
      propertyRightList: [],
      billsList: [],
      specialList: [],
      hlTalentsFileList: [],
      militaryFileList: [],
      frPersonnelFileList: [],
      userData: {
        idCardNo: "",
      },
      // baseUrl: "http://116.136.156.117:15566",
      baseUrl: "https://ygrx.ordos.edu.cn:15566", //正式环境

      isPlatformType1: false,
      isPlatformType2: false,
      isPlatformType3: false,
      isPlatformType10: false,
      isPlatformType11: false,
      isPlatformType12: false,
      isPlatformType13: false,
      maxCount: 8,
    };
  },
  created() {
    this.getPageData();
  },
  mounted() {
    this.setPageHeight();
  },
  methods: {
    //
    getPageData() {
      this.userData.idCardNo = sessionStorage.getItem("account");
      this.platformType = sessionStorage.getItem("platformType");
      this.isPlatformType1 = this.platformType == 1;
      if (this.isPlatformType1) {
        // this.baseUrl = "http://116.136.156.117:14455";
        this.baseUrl = "https://ygrx.ordos.edu.cn:14455"; //正式环境
      }
      this.isPlatformType2 = this.platformType == 2;
      this.isPlatformType3 = this.platformType == 3;
      this.isPlatformType10 = this.platformType == 10;
      this.isPlatformType11 = this.platformType == 11;
      this.isPlatformType12 = this.platformType == 12;
      this.isPlatformType13 = this.platformType == 13;
      this.areaCode = this.$route.query.area;
      // if(this.areaCode == 150622){
      //     this.maxCount = 999;
      // }
      if (
        this.isPlatformType1 ||
        this.isPlatformType2 ||
        this.isPlatformType3
      ) {
        this.pageType = 1;
      } else if (this.isPlatformType13) {
        this.pageType = 2;
      } else if (
        this.isPlatformType10 ||
        this.isPlatformType11 ||
        this.isPlatformType12
      ) {
        this.pageType = 3;
      }
      this.voucherStr = this.$route.query.vStr || "产权相关资料证明";
      this.getUserData();
    },

    getUserData() {
      let params = {
        sfzhm: this.userData.idCardNo,
        xxlx: this.pageType,
      };
      let reqUrl = "";
      if (this.isPlatformType1) {
        reqUrl = "/msb/yry/getFiles";
      } else if (
        this.isPlatformType2 ||
        this.isPlatformType3 ||
        this.isPlatformType13
      ) {
        reqUrl = "/msb/file/getFiles";
      } else if (
        this.isPlatformType10 ||
        this.isPlatformType11 ||
        this.isPlatformType12
      ) {
        reqUrl = "/msb/children/file/getFiles";
      }
      if (!reqUrl) {
        return;
      }
      this.$http({
        url: reqUrl,
        method: "GET",
        params: params,
      })
        .then((res) => {
          if (res.code == "0000") {
            this.setPageData(res.data);
          } else {
            // this.$message.error(res.message);
          }
        })
        .catch(() => {});
    },

    setPageData(data) {
      let files = data.files || [];
      files.forEach((item) => {
        if (item.tplx == 1) {
          this.residenceBookletList.push({
            url: this.baseUrl + item.tpdz,
          });
        } else if (item.tplx == 2) {
          this.propertyRightList.push({
            url: this.baseUrl + item.tpdz,
          });
        } else if (item.tplx == 3) {
          this.billsList.push({
            url: this.baseUrl + item.tpdz,
          });
        } else if (item.tplx == 4) {
          (this.isPlatformType1 ||
            this.isPlatformType2 ||
            this.isPlatformType3 ||
            this.isPlatformType13) &&
            this.specialList.push({
              url: this.baseUrl + item.tpdz,
            });
          this.isPlatformType10 &&
            this.hlTalentsFileList.push({
              url: this.baseUrl + item.tpdz,
            });
          this.isPlatformType11 &&
            this.militaryFileList.push({
              url: this.baseUrl + item.tpdz,
            });
          this.isPlatformType12 &&
            this.frPersonnelFileList.push({
              url: this.baseUrl + item.tpdz,
            });
        }
      });
    },

    beforeRead(file) {
      console.log("beforeRead===>>>", file);
      if (file.type !== "image/jpeg") {
        this.$toast("请上传 jpg 格式图片");
        return false;
      }
      return true;
    },

    onOversize(file) {
      this.$toast("文件大小不能超过 10Mb");
    },

    afterRead(file, type) {
      let reqUrl = "";
      if (this.isPlatformType1) {
        reqUrl = "/msb/yry/upload";
      } else if (
        this.isPlatformType2 ||
        this.isPlatformType3 ||
        this.isPlatformType13
      ) {
        reqUrl = "/msb/file/upload";
      } else if (
        this.isPlatformType10 ||
        this.isPlatformType11 ||
        this.isPlatformType12
      ) {
        reqUrl = "/msb/children/file/upload";
      }
      if (!reqUrl) {
        return;
      }
      file.status = "uploading";
      file.message = "上传中...";
      let form = new FormData();
      form.append("multipartFile", file.file);
      form.append("sfzhm", this.userData.idCardNo);
      this.$http({
        url: reqUrl,
        method: "POST",
        data: form,
      })
        .then((res) => {
          console.log("res====>>>>", res);
          if (res.code == "0000") {
            file.status = "done";
            file.message = "上传完成";
            file.url = res.data.url;
          } else {
            this.$toast(res.message);
            file.status = "failed";
            file.message = "上传失败";
            let arr = [];
            if (type == 1) {
              arr = this.residenceBookletList;
            } else if (type == 2) {
              arr = this.propertyRightList;
            } else if (type == 3) {
              arr = this.billsList;
            } else if (type == 4) {
              arr = this.specialList;
            } else if (type == 5) {
              arr = this.hlTalentsFileList;
            } else if (type == 6) {
              arr = this.militaryFileList;
            } else if (type == 7) {
              arr = this.frPersonnelFileList;
            }
            for (let i = arr.length - 1; i >= 0; i--) {
              if (!arr[i].url) {
                arr.splice(i, 1);
              }
            }
          }
        })
        .catch((e) => {
          console.error("e===>>>", e.message);
        });
    },

    async beforeDelete() {
      return new Promise((resolve, reject) => {
        this.$dialog
          .confirm({
            message: "确定要删除该图片吗？",
          })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      });
    },

    //  提交
    onSubmit() {
      let data = this.getDataStr();
      let reqUrl = "";
      if (this.isPlatformType1) {
        reqUrl = "/msb/yry/file/insert";
      } else if (
        this.isPlatformType2 ||
        this.isPlatformType3 ||
        this.isPlatformType13
      ) {
        reqUrl = "/msb/file/insert";
      } else if (
        this.isPlatformType10 ||
        this.isPlatformType11 ||
        this.isPlatformType12
      ) {
        reqUrl = "/msb/children/file/insert";
      }
      if (!reqUrl) {
        return;
      }
      this.$http({
        url: reqUrl,
        method: "POST",
        data: data,
      })
        .then((res) => {
          if (res.code == "0000") {
            this.$dialog
              .alert({
                title: "提交结果",
                message: "提交成功，请耐心等待审核！",
              })
              .then(() => {
                // on close
                this.$router.replace({ name: "index" });
              });
          } else {
            this.$toast(res.message);
          }
        })
        .catch(() => {});
    },

    getDataStr() {
      console.log("===>>>", this.pageType);
      let data = {
        sfzhm: this.userData.idCardNo,
        img: "",
        xxlx: this.pageType,
      };

      let rbList = this.residenceBookletList.map((item) => {
        return item.url + "-1";
      });
      for (let i = 0; i < rbList.length; i++) {
        if (rbList[i].indexOf(this.baseUrl) != -1) {
          rbList[i] = rbList[i].substring(this.baseUrl.length);
        }
      }
      let str1 = "";
      if (rbList.length) {
        str1 += rbList.join();
      }
      let str2, str3, str4;
      if (
        this.isPlatformType1 ||
        this.isPlatformType2 ||
        this.isPlatformType3 ||
        this.isPlatformType13
      ) {
        let prList = this.propertyRightList.map((item) => {
          return item.url + "-2";
        });
        for (let i = 0; i < prList.length; i++) {
          if (prList[i].indexOf(this.baseUrl) != -1) {
            prList[i] = prList[i].substring(this.baseUrl.length);
          }
        }
        str2 = "";
        if (prList.length) {
          str2 += prList.join();
        }

        let bList = this.billsList.map((item) => {
          return item.url + "-3";
        });
        for (let i = 0; i < bList.length; i++) {
          if (bList[i].indexOf(this.baseUrl) != -1) {
            bList[i] = bList[i].substring(this.baseUrl.length);
          }
        }
        str3 = "";
        if (bList.length) {
          str3 += bList.join();
        }
        let sList = this.specialList.map((item) => {
          return item.url + "-4";
        });
        for (let i = 0; i < sList.length; i++) {
          if (sList[i].indexOf(this.baseUrl) != -1) {
            sList[i] = sList[i].substring(this.baseUrl.length);
          }
        }
        str4 = "";
        if (sList.length) {
          str4 += sList.join();
        }
      } else if (
        this.isPlatformType10 ||
        this.isPlatformType11 ||
        this.isPlatformType12
      ) {
        let hltList = this.hlTalentsFileList.map((item) => {
          return item.url + "-4";
        });
        for (let i = 0; i < hltList.length; i++) {
          if (hltList[i].indexOf(this.baseUrl) != -1) {
            hltList[i] = hltList[i].substring(this.baseUrl.length);
          }
        }
        str2 = "";
        if (hltList.length) {
          str2 += hltList.join();
        }

        let mfList = this.militaryFileList.map((item) => {
          return item.url + "-4";
        });
        for (let i = 0; i < mfList.length; i++) {
          if (mfList[i].indexOf(this.baseUrl) != -1) {
            mfList[i] = mfList[i].substring(this.baseUrl.length);
          }
        }
        str3 = "";
        if (mfList.length) {
          str3 += mfList.join();
        }
        let frpfList = this.frPersonnelFileList.map((item) => {
          return item.url + "-4";
        });
        for (let i = 0; i < frpfList.length; i++) {
          if (frpfList[i].indexOf(this.baseUrl) != -1) {
            frpfList[i] = frpfList[i].substring(this.baseUrl.length);
          }
        }
        str4 = "";
        if (frpfList.length) {
          str4 += frpfList.join();
        }
      }
      data.img = str1;
      str2 && (data.img = data.img ? data.img + "," + str2 : str2);
      str3 && (data.img = data.img ? data.img + "," + str3 : str3);
      str4 && (data.img = data.img ? data.img + "," + str4 : str4);
      return data;
    },

    //	设置页面最小高度
    setPageHeight() {
      this.pageHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.pageHeight = document.documentElement["clientHeight"];
      };
    },
  },
};
</script>

<style scoped>
.voucherPage {
  box-sizing: border-box;
  position: relative;
  padding-bottom: 80px;
}
.voucherPage .form .subtitle {
  font-size: 36px;
  font-weight: 500;
  color: #333333;
  padding: 30px;
  border-bottom: 2px solid #eeeeee;
  line-height: 54px;
}
.voucherPage .form .subtitle .titleIcon {
  display: inline-block;
  width: 4px;
  height: 36px;
  vertical-align: text-top;
  margin-right: 10px;
  background: linear-gradient(315deg, #b1e0dc 0%, #00c3ac 100%);
}
.voucherPage .form .formChild {
  padding: 20px 60px;
}
.voucherPage .form .formItem {
  margin-bottom: 50px;
  width: 630px;
  min-height: 350px;
  text-align: center;
  background: #ffffff;
}
.voucherPage .form .formItem .uploadBox {
  width: 630px;
  min-height: 350px;
  text-align: center;
  padding-top: 86px;
}
.voucherPage .form .formItem .uploadBox .tips {
  margin-top: 27px;
  font-size: 32px;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 32px;
}
.voucherPage /deep/ .van-uploader__input-wrapper {
  background: #f6f6f7;
}
.voucherPage /deep/ .van-uploader__preview .van-uploader__preview-image {
  width: 630px;
  height: 390px;
}
.voucherPage .btnBox {
  width: 750px;
  background: #ffffff;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 20px 0;
}
.voucherPage .btnBox button {
  width: 90%;
  margin: 0 auto;
}
</style>
